import { useState, useEffect, useContext } from "react";

import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import PropTypes from "prop-types";
import { Link, useNavigate, useParams } from "react-router-dom";
import Divider from "@mui/material/Divider";

import {
  Card,
  Grid,
  Paper,
  Tooltip,
  Icon,
  FormControl,
  Select,
  MenuItem,
} from "@mui/material";
import Menu from "@mui/material/Menu";

import {
  acceptedImageMimeTypes,
  acceptedImageSizeInMb,
  acceptedVideoMimeTypes,
  acceptedVideoSizeInMb,
  acceptedDocumentMimeTypes,
  acceptedPropertyImageSizeInMb,
} from "services/acceptedMimeTypes";

import Switch from "@mui/material/Switch";

import ArgonBox from "components/ArgonBox";
import ArgonTypography from "components/ArgonTypography";

import HostHiveCommentSection from "components/HostHiveCommentSection";

import RemoveCircleOutlineIcon from "@mui/icons-material/RemoveCircleOutline";
import ArgonButton from "components/ArgonButton";
import BookingsApi from "api/bookingsApi";
import { formatIsoDate } from "services/dateUtils";
import CompaniesApi from "api/companyApi";
import HostHiveDialog from "components/HostHiveDialog";
import ArgonBadge from "components/ArgonBadge";
import PropertyInfo from "layouts/management/properties/new-property/components/PropertyInfo";
import CompanySelect from "layouts/management/components/CompanySelect";
import PropertySelect from "layouts/management/components/PropertySelect";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { TimePicker } from "@mui/x-date-pickers/TimePicker";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import dayjs from "dayjs";
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";
import { createTheme, ThemeProvider } from "@mui/material";
import HostHiveLoading from "components/HostHiveLoading";
import { set } from "lodash";
import ServiceAppointmentChangeRequest from "layouts/management/components/ServiceAppointmentChangeRequest";
import DeclineMessageDataCard from "layouts/management/components/DeclineMessageDataCard";
import RecurrenceDialog from "layouts/management/components/RecurrenceDialog";
import RecurrenceSeriesDialog from "layouts/management/components/RecurrenceSeriesDialog";
import UsersSelect from "layouts/management/components/UsersSelect";
import HostHiveImages from "components/HostHiveImages";
import { useTranslation } from "react-i18next";
import { showDeleteConfirmation } from "services/confirmDelete";
import ReadOnlyPropertyDetails from "layouts/management/properties/properties-list/components/read-only-property-details";
import ArgonProgress from "components/ArgonProgress";
import ChangeRequestsApi from "api/changeRequestsApi";
import DateSelection from "layouts/management/calendar/components/CreateEventWizard/components/DateSelection";
import ReservationSelection from "../CreateEventWizard/components/ReservationSelection";
import PropertySelection from "layouts/management/calendar/components/CreateEventWizard/components/PropertySelection";
import ServiceNameSelection from "layouts/management/calendar/components/CreateEventWizard/components/ServiceNameSelection";
import SelectServicePartner from "layouts/management/calendar/components/CreateEventWizard/components/ServicePartnerSelection";
import SubAssigneeSelection from "layouts/management/calendar/components/CreateEventWizard/components/SubAssigneeSelection";
import EventOptions from "layouts/management/calendar/components/CreateEventWizard/components/EventOptions";
import BookingReportApi from "api/bookingReportApi";
import EventMenu from "layouts/management/calendar/components/EventMenu";
import { UserAuth } from "context/AuthContext";
import { isRouteAuthorized } from "services/checkRouteProjection";
import HostHiveSnackbar from "components/HostHiveSnackbar";

function ViewEventDataCard({
  bookingPayload,
  setBookingPayload,
  setNextDisabled,
  userSelections,
  responseData,
  setResponseData,
  setUserSelections,
  isInCreationMode,
  closeEventDataCard,
  handleRefetchBookings,
  readOnly = false,
}) {
  const { t } = useTranslation("common");
  const { role, hasRole, user, hasOneOfRoles, subscriptionTier } = UserAuth();
  const isAuthorized = isRouteAuthorized(
    "calendarPlus",
    role,
    subscriptionTier
  );
  const { renderSuccessSB, openSuccessSB } = HostHiveSnackbar();
  const company = JSON.parse(localStorage.getItem("companyDetails"));
  // const [ownerHasCalendarPlus, setOwnerHasCalendarPlus] =
  //   useState(isAuthorized);
  const changeRequestsApi = ChangeRequestsApi();
  const bookingsApi = BookingsApi();
  const companiesApi = CompaniesApi();
  const bookingReportApi = BookingReportApi();
  const [loadingNextCheckin, setLoadingNextCheckin] = useState(false);
  const [changeRequestPending, setChangeRequestPending] = useState(null);
  const [changeRequestData, setChangeRequestsData] = useState(null);
  const [bookingAssignees, setBookingAssignees] = useState([]);
  const [fetchChangeRequestDetails, setFetchChangeRequestDetails] =
    useState(false);
  const [bookingImages, setBookingImages] = useState(
    bookingPayload?.images || []
  );
  useEffect(() => {
    if (setNextDisabled) {
      setNextDisabled(false);
    }
  }, []);

  useEffect(() => {
    console.log("bookingPayload load", bookingPayload);
    async function getNextCheckin() {
      try {
        setLoadingNextCheckin(true);
        const response = await bookingsApi.getNextCheckin(bookingPayload?.id);

        bookingPayload.nextCheckin = response.data;
        console.log(response?.data);
      } catch (error) {
        console.error(error);
      }
      setLoadingNextCheckin(false);
    }
    if (!bookingPayload?.nextCheckin && isInCreationMode === false) {
      getNextCheckin();
    }

    async function getOpenChangeRequest() {
      let params = {
        entityId: bookingPayload?.id,
        entityType: "booking",
        openRequests: true,
      };
      try {
        const changeRequestsResponse =
          await changeRequestsApi.getChangeRequests(params);
        if (changeRequestsResponse?.data?.status === "active") {
          setChangeRequestPending(true);
          console.log("Change Request Data", changeRequestsResponse?.data);
          setChangeRequestsData(changeRequestsResponse?.data);
        } else {
          setChangeRequestPending(false);
        }
      } catch (error) {
        setChangeRequestPending(false);
      }
    }

    async function getOwnerPermittedActions() {
      if (company?.id !== bookingPayload?.companyId) {
        console.log(
          "Not our company's booking, fetching owner permitted actions"
        );
        const ownerHasPermission = await companiesApi.getCompanySubscription(
          bookingPayload?.companyId,
          { paidTier: true }
        );
        console.log("Owner Has Permission", ownerHasPermission?.data?.message);
        // setOwnerHasCalendarPlus(ownerHasPermission?.data?.message);
        // setOwnerHasCalendarPlus(false);
      } else {
        console.log(
          "Our company's booking, no need to fetch owner permitted actions"
        );
      }
    }

    async function getBookingImages() {
      try {
        const response = await bookingReportApi.getBookingReportImages(
          bookingPayload?.id,
          company?.id,
          bookingPayload?.propertyId
        );
        // console.log("Booking Images", response?.data);
        setBookingImages(response?.data);
      } catch (error) {
        console.error(error);
      }
    }

    async function getBookingAssignees() {
      try {
        const response = await bookingsApi.getBookingAssignees(
          bookingPayload?.id
        );
        console.log("Booking Assignees", response?.data);

        let usersOptions = response?.data.map((user) => ({
          value: user.assigneeSubId,
          label: `${user.firstName} ${user.lastName}`,
        }));
        setBookingAssignees(usersOptions);
      } catch (error) {
        console.error(error);
      }
    }

    if (bookingPayload?.id && isInCreationMode === false) {
      getOpenChangeRequest();
      getBookingImages();
      if (bookingPayload?.assignedCompanyId === company?.id) {
        getBookingAssignees();
      }
    }

    // getOwnerPermittedActions();
  }, [bookingPayload, fetchChangeRequestDetails]);

  const [showEditAssignee, setShowEditAssignee] = useState(false);
  const [showEditServicePartner, setShowEditServicePartner] = useState(false);
  const [showEditProperty, setShowEditProperty] = useState(false);
  const [showEditServiceName, setShowEditServiceName] = useState(false);
  const [showEditChecklist, setShowEditChecklist] = useState(false);
  const [showEditCategory, setShowEditCategory] = useState(false);
  const [savingUser, setSavingUser] = useState(false);
  const [showEditDateAndTime, setShowEditDateAndTime] = useState(false);
  const [showEditReservationDetails, setShowEditReservationDetails] =
    useState(false);
  const [saveReservationDetailsDisabled, setSaveReservationDetailsDisabled] =
    useState(false);

  const [showEditRecurrence, setShowEditRecurrence] = useState(false);
  const [showEditEstimatedDuration, setShowEditEstimatedDuration] =
    useState(false);

  const [photoUploadInProgress, setPhotoUploadInProgress] = useState(false);
  const [photoProgress, setPhotoProgress] = useState(0);
  const [erroredPhotos, setErroredPhotos] = useState([]);

  const handleUpdateDateAndTime = async () => {
    console.log("handleUpdateDateAndTime", bookingPayload);
    let dateTimePayload = {
      serviceScheduleDate: bookingPayload?.serviceScheduleDate,
      serviceScheduleTime: bookingPayload?.serviceScheduleTime,
    };
    try {
      await bookingsApi.updateBookingDateTime(
        bookingPayload?.id,
        dateTimePayload
      );
      setShowEditDateAndTime(false);
    } catch (error) {
      console.error(error);
    }
  };

  const handleUpdateReservationDetails = async () => {
    // console.log("handleUpdateReservationDetails", bookingPayload);

    let checkin = bookingPayload?.checkIn;
    let checkout = bookingPayload?.checkOut;
    let scheduleServiceDate = bookingPayload?.serviceScheduleDate;

    console.log("Checkin", checkin);
    console.log("Checkout", checkout);
    console.log("Service Date", scheduleServiceDate);
    if (!checkin || !checkout) {
      alert("Please select a check-in and check-out date");
      setBookingPayload({ ...bookingPayload, checkIn: null, checkOut: null });
      return;
    }

    // if checkout < checkin, alert user
    if (checkin && checkout && checkin > checkout) {
      alert("Check-out date must be after check-in date");
      setBookingPayload({ ...bookingPayload, checkIn: null, checkOut: null });
      return;
    }

    if (
      checkin &&
      checkout &&
      (bookingPayload?.serviceScheduleDate <= checkin ||
        scheduleServiceDate < checkout)
    ) {
      alert(
        "Check-In and Check-Out dates must be prior to Scheduled Service Date"
      );
      setBookingPayload({ ...bookingPayload, checkIn: null, checkOut: null });
      return;
    }

    let reservationDetails = {
      checkIn: bookingPayload?.checkIn,
      checkOut: bookingPayload?.checkOut,
    };
    try {
      await bookingsApi.updateBookingReservationDetails(
        // "bookingPayload",
        bookingPayload?.id,
        reservationDetails
      );
      setShowEditReservationDetails(false);
    } catch (error) {
      console.error(error);
    }
  };

  const clearReservationDetails = async () => {
    try {
      let reservationData = {
        checkIn: null,
        checkOut: null,
      };

      await bookingsApi.updateBookingReservationDetails(
        bookingPayload?.id,
        reservationData
      );

      setBookingPayload({ ...bookingPayload, checkIn: null, checkOut: null });
    } catch (error) {
      console.error("Error saving reservation details", error);
    }
  };
  const [imageXL, setImageXL] = useState(false);

  const handleRemoveFromBooking = async (userId) => {
    try {
      await bookingsApi.removeUserFromBooking(bookingPayload?.id, userId);
      let updatedAssignees = bookingAssignees.filter(
        (assignee) => assignee.id !== userId
      );
      setBookingAssignees(updatedAssignees);

      // handleRefetchBookings();
    } catch (error) {
      console.error(error);
    }
  };

  const uploadHandler = async (event) => {
    setPhotoUploadInProgress(true);

    const uploadedImages = event.target.files;
    const uploadedImagesArray = Array.from(uploadedImages);
    const imagesArray = [];
    const photoLength = uploadedImagesArray.length;
    const maxSizeInMB = acceptedImageSizeInMb; // Replace x with the maximum file size in MB
    const allowedMimeTypes = acceptedImageMimeTypes; // Add or remove MIME types as needed

    for (let i = 0; i < uploadedImagesArray.length; i++) {
      setPhotoProgress((i / photoLength) * 100);
      const image = uploadedImagesArray[i];

      if (image.size > maxSizeInMB * 1024 * 1024) {
        console.log(`Error: File size exceeds ${maxSizeInMB}MB`);
        let imageSize = image.size / (1024 * 1024);
        imageSize = imageSize.toFixed(2);
        setErroredPhotos((prevErroredPhotos) => [
          ...prevErroredPhotos,
          `${image.name} - File size exceeds ${maxSizeInMB}MB - ${imageSize}MB`,
        ]);
        continue; // Skip this file
      }

      // Check MIME type
      if (!allowedMimeTypes.includes(image.type)) {
        console.log(
          `Error: Invalid file type. Allowed types are: ${allowedMimeTypes.join(
            ", "
          )}`
        );
        setErroredPhotos((prevErroredPhotos) => [
          ...prevErroredPhotos,
          `${image.name} - Invalid file type - ${image.type}`,
        ]);
        continue; // Skip this file
      }

      const fileType = image.type;
      const fileName = image.name;

      const formData = new FormData();
      formData.append("file", image);
      try {
        const response = await bookingReportApi.updateBookingReportImages(
          bookingPayload?.id,
          bookingPayload?.companyId,
          bookingPayload?.propertyId,
          fileName,
          fileType,
          bookingPayload?.summary,
          formData
        );

        imagesArray.push(response.data.images); // Add the uploaded image to the imagesArray
        setBookingImages(response.data.images);
        openSuccessSB(
          `Image uploaded successfully`,
          `Successfully uploaded image`
        );
      } catch (error) {
        console.error(error);
      }
    }

    setPhotoUploadInProgress(false);
    setPhotoProgress(0);

    // Update the bookingImages state with the uploaded images
  };

  const handleImageDelete = async (index, fileName) => {
    console.log("handleImageDelete", fileName);
    try {
      const response = await bookingReportApi.deleteBookingReportImage(
        bookingPayload?.id,
        bookingPayload?.companyId,
        bookingPayload?.propertyId,
        fileName
      );

      // Update the bookingImages state by removing the image at the specified index
      setBookingImages((prevImages) => {
        const newImages = [...prevImages];
        newImages.splice(index, 1);
        return newImages;
      });
      openSuccessSB(`Image deleted successfully`, `Successfully deleted image`);
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <>
      <Card>
        <HostHiveDialog
          open={showEditRecurrence}
          fullWidth={true}
          includeSave={true}
          includeClose={true}
          maxWidth="xs"
          onClose={() => setShowEditRecurrence(false)}
          onSave={() => setShowEditRecurrence(false)}
          // saveDisabled={savingUser}
          title="Update Recurrence Series"
          fullScreen={false}
          cancelButtonText="Cancel"
          submitButtonText="Update"
          showStatusBadge={false}
          statusColor="success"
          statusContent="Confirmed"
          dialogObjects={
            <DateSelection
              setNextDisabled={setNextDisabled}
              bookingPayload={bookingPayload}
              setBookingPayload={setBookingPayload}
              setUserSelections={setUserSelections}
              userSelections={userSelections}
              responseData={responseData}
              setResponseData={setResponseData}
              isInCreationMode={isInCreationMode}
              isOwner={bookingPayload?.isOwner}
            />
          }
        />
        <HostHiveDialog
          open={showEditEstimatedDuration}
          fullWidth={true}
          includeSave={true}
          includeClose={true}
          maxWidth="xs"
          onClose={() => setShowEditEstimatedDuration(false)}
          onSave={() => setShowEditEstimatedDuration(false)}
          // saveDisabled={savingUser}
          title="Update Estimated Duration"
          fullScreen={false}
          cancelButtonText="Cancel"
          submitButtonText="Update"
          showStatusBadge={false}
          statusColor="success"
          statusContent="Confirmed"
          dialogObjects={
            <DateSelection
              setNextDisabled={setNextDisabled}
              bookingPayload={bookingPayload}
              setBookingPayload={setBookingPayload}
              setUserSelections={setUserSelections}
              userSelections={userSelections}
              responseData={responseData}
              setResponseData={setResponseData}
              isInCreationMode={isInCreationMode}
              isOwner={bookingPayload?.isOwner}
            />
          }
        />
        <HostHiveDialog
          open={showEditDateAndTime}
          fullWidth={true}
          includeSave={true}
          includeClose={true}
          maxWidth="xs"
          onClose={() => setShowEditDateAndTime(false)}
          onSave={handleUpdateDateAndTime}
          // saveDisabled={savingUser}
          title="Update Date & Time"
          fullScreen={false}
          cancelButtonText="Cancel"
          submitButtonText="Update"
          showStatusBadge={false}
          statusColor="success"
          statusContent="Confirmed"
          dialogObjects={
            <DateSelection
              setNextDisabled={setNextDisabled}
              bookingPayload={bookingPayload}
              setBookingPayload={setBookingPayload}
              setUserSelections={setUserSelections}
              userSelections={userSelections}
              responseData={responseData}
              setResponseData={setResponseData}
              isInCreationMode={isInCreationMode}
              isOwner={bookingPayload?.isOwner}
            />
          }
        />
        <HostHiveDialog
          open={showEditReservationDetails}
          fullWidth={true}
          includeSave={true}
          includeClose={true}
          maxWidth="xs"
          onClose={() => {
            setShowEditReservationDetails(false);
          }}
          onSave={handleUpdateReservationDetails}
          // saveDisabled={savingUser}
          title="Update Reservation Details"
          fullScreen={false}
          cancelButtonText="Cancel"
          submitButtonText="Update"
          showStatusBadge={false}
          statusColor="success"
          statusContent="Confirmed"
          // saveDisabled={saveReservationDetailsDisabled}
          dialogObjects={
            <ReservationSelection
              saveReservationDetailsDisabled={saveReservationDetailsDisabled}
              setSaveReservationDetailsDisabled={
                setSaveReservationDetailsDisabled
              }
              setNextDisabled={setNextDisabled}
              bookingPayload={bookingPayload}
              setBookingPayload={setBookingPayload}
              setUserSelections={setUserSelections}
              userSelections={userSelections}
              responseData={responseData}
              setResponseData={setResponseData}
              isInCreationMode={isInCreationMode}
              isOwner={bookingPayload?.isOwner}
            />
          }
        />
        <HostHiveDialog
          open={showEditCategory}
          fullWidth={true}
          includeSave={true}
          includeClose={true}
          maxWidth="xs"
          onClose={() => setShowEditCategory(false)}
          onSave={() => setShowEditCategory(false)}
          // saveDisabled={savingUser}
          title="Update Category"
          fullScreen={false}
          cancelButtonText="Cancel"
          submitButtonText="Update"
          showStatusBadge={false}
          statusColor="success"
          statusContent="Confirmed"
          dialogObjects={
            <EventOptions
              setNextDisabled={setNextDisabled}
              bookingPayload={bookingPayload}
              setBookingPayload={setBookingPayload}
              setUserSelections={setUserSelections}
              userSelections={userSelections}
              responseData={responseData}
              setResponseData={setResponseData}
              isInCreationMode={isInCreationMode}
              isOwner={bookingPayload?.isOwner}
            />
          }
        />
        <HostHiveDialog
          open={showEditChecklist}
          fullWidth={true}
          includeSave={true}
          includeClose={true}
          maxWidth="xs"
          onClose={() => setShowEditChecklist(false)}
          onSave={() => setShowEditChecklist(false)}
          // saveDisabled={savingUser}
          title="Update Checklist"
          fullScreen={false}
          cancelButtonText="Cancel"
          submitButtonText="Update"
          showStatusBadge={false}
          statusColor="success"
          statusContent="Confirmed"
          dialogObjects={
            <EventOptions
              setNextDisabled={setNextDisabled}
              bookingPayload={bookingPayload}
              setBookingPayload={setBookingPayload}
              setUserSelections={setUserSelections}
              userSelections={userSelections}
              responseData={responseData}
              setResponseData={setResponseData}
              isInCreationMode={isInCreationMode}
              isOwner={bookingPayload?.isOwner}
            />
          }
        />
        <HostHiveDialog
          open={showEditServiceName}
          fullWidth={true}
          includeSave={true}
          includeClose={true}
          maxWidth="xs"
          onClose={() => setShowEditServiceName(false)}
          onSave={() => setShowEditServiceName(false)}
          // saveDisabled={savingUser}
          title="Update Service Name"
          fullScreen={false}
          cancelButtonText="Cancel"
          submitButtonText="Update"
          showStatusBadge={false}
          statusColor="success"
          statusContent="Confirmed"
          dialogObjects={
            <ServiceNameSelection
              setNextDisabled={setNextDisabled}
              bookingPayload={bookingPayload}
              setBookingPayload={setBookingPayload}
              setUserSelections={setUserSelections}
              userSelections={userSelections}
              responseData={responseData}
              setResponseData={setResponseData}
              isInCreationMode={isInCreationMode}
              isOwner={bookingPayload?.isOwner}
              handleRefetchBookings={handleRefetchBookings}
            />
          }
        />
        <HostHiveDialog
          open={showEditProperty}
          fullWidth={true}
          includeSave={true}
          includeClose={true}
          maxWidth="xs"
          onClose={() => setShowEditProperty(false)}
          onSave={() => setShowEditProperty(false)}
          // saveDisabled={savingUser}
          title="Update Property Selection"
          fullScreen={false}
          cancelButtonText="Cancel"
          submitButtonText="Update"
          showStatusBadge={false}
          statusColor="success"
          statusContent="Confirmed"
          dialogObjects={
            <PropertySelection
              setNextDisabled={setNextDisabled}
              bookingPayload={bookingPayload}
              setBookingPayload={setBookingPayload}
              setUserSelections={setUserSelections}
              userSelections={userSelections}
              responseData={responseData}
              setResponseData={setResponseData}
              isInCreationMode={isInCreationMode}
              isOwner={bookingPayload?.isOwner}
            />
          }
        />
        <HostHiveDialog
          open={showEditServicePartner}
          fullWidth={true}
          includeSave={true}
          includeClose={true}
          maxWidth="xs"
          onClose={() => setShowEditServicePartner(false)}
          onSave={() => setShowEditServicePartner(false)}
          saveDisabled={savingUser}
          title="Update Service Partner"
          fullScreen={false}
          cancelButtonText="Cancel"
          submitButtonText="Done"
          showStatusBadge={false}
          statusColor="success"
          statusContent="Confirmed"
          dialogObjects={
            <SelectServicePartner
              setNextDisabled={setNextDisabled}
              bookingPayload={bookingPayload}
              setBookingPayload={setBookingPayload}
              setUserSelections={setUserSelections}
              userSelections={userSelections}
              responseData={responseData}
              setResponseData={setResponseData}
              isInCreationMode={isInCreationMode}
              isOwner={bookingPayload?.isOwner}
              onClose={() => setShowEditServicePartner(false)}
              handleRefetchBookings={handleRefetchBookings}
            />
          }
        />
        <HostHiveDialog
          open={showEditAssignee}
          fullWidth={true}
          includeSave={true}
          includeClose={true}
          maxWidth="xs"
          onClose={() => setShowEditAssignee(false)}
          onSave={() => setShowEditAssignee(false)}
          saveDisabled={savingUser}
          title="Update Team Assignment"
          fullScreen={false}
          cancelButtonText="Cancel"
          submitButtonText="Done"
          showStatusBadge={false}
          statusColor="success"
          statusContent="Confirmed"
          dialogObjects={
            <SubAssigneeSelection
              setBookingPayload={setBookingPayload}
              bookingPayload={bookingPayload}
              bookingAssignees={bookingAssignees}
              setBookingAssignees={setBookingAssignees}
            />
          }
        />
        <ArgonBox
          pl={3}
          pr={1.25}
          pt={1.25}
          pb={3}
          boxShadow={2}
          borderRadius={1}
          bgcolor="background.paper"
        >
          <Grid container spacing={2}>
            {renderSuccessSB}
            {/* Menu Icon */}
            {!isInCreationMode ? (
              <Grid item xs={12} display="flex" justifyContent="flex-end">
                <EventMenu
                  bookingPayload={bookingPayload}
                  setBookingPayload={setBookingPayload}
                  bookingImages={bookingImages}
                  setBookingImages={setBookingImages}
                  disableViewPropertyDetails={false}
                  changeRequestPending={changeRequestPending}
                  isAppointmentOwner={bookingPayload?.isOwner}
                  changeRequestData={changeRequestData}
                  closeEventDataCard={closeEventDataCard}
                  handleRefetchBookings={handleRefetchBookings}
                  setFetchChangeRequestDetails={() =>
                    setFetchChangeRequestDetails(!fetchChangeRequestDetails)
                  }
                  // ownerHasCalendarPlus={ownerHasCalendarPlus}
                />
              </Grid>
            ) : null}
            <Grid item xs={12}>
              <ArgonTypography variant="h6" fontWeight="bold">
                Property:
              </ArgonTypography>
              {/* Get property name from userSelections by bookingPayload.propertyId */}
              <ArgonTypography variant="body1" color="textSecondary">
                {userSelections[bookingPayload?.propertyId] || "Default Value"}
              </ArgonTypography>
              {isInCreationMode ? (
                <ArgonBox pl={2}>
                  <ArgonTypography
                    variant="caption"
                    textTransform="capitalize"
                    sx={{
                      ":hover": {
                        textDecoration: "underline",
                        color: "primary.main",
                      },
                      cursor: "pointer",
                      fontStyle: "italic",
                    }}
                    onClick={() => setShowEditProperty(true)}
                  >
                    {/* {t("serviceAssignments.buttons.editAssignee")} */}
                    Edit Property Selection
                  </ArgonTypography>
                </ArgonBox>
              ) : null}
            </Grid>
            {/* Service Date & Time */}
            <Grid item xs={12}>
              <ArgonTypography variant="h6" fontWeight="bold">
                {t("serviceAssignments.eventDataCard.serviceDateAndTime")}:
              </ArgonTypography>
              <ArgonTypography variant="body1" color="textSecondary">
                {bookingPayload?.serviceScheduleDate}
                {bookingPayload?.serviceScheduleTime ? (
                  <>
                    {" @ "}
                    {bookingPayload?.serviceScheduleTime}
                  </>
                ) : null}
                {/* {bookingPayload?.timeObject?.startTime || ""} */}
              </ArgonTypography>
              <ArgonBox pl={2}>
                {bookingPayload?.isOwner ? (
                  <ArgonTypography
                    variant="caption"
                    textTransform="capitalize"
                    sx={{
                      ":hover": {
                        textDecoration: "underline",
                        color: "primary.main",
                      },
                      cursor: "pointer",
                      fontStyle: "italic",
                    }}
                    onClick={() => setShowEditDateAndTime(true)}
                  >
                    {/* {t("serviceAssignments.buttons.editAssignee")} */}
                    Edit Date & Time
                  </ArgonTypography>
                ) : isAuthorized ? (
                  <ArgonTypography
                    variant="caption"
                    textTransform="capitalize"
                    sx={{
                      ":hover": {
                        textDecoration: "underline",
                        color: "primary.main",
                      },
                      cursor: "pointer",
                      fontStyle: "italic",
                    }}
                    // onClick={() => setShowEditDateAndTime(true)}
                  >
                    {/* {t("serviceAssignments.buttons.editAssignee")} */}
                    Request Change
                  </ArgonTypography>
                ) : null}
              </ArgonBox>
            </Grid>

            {!isInCreationMode && (
              <Grid item xs={12}>
                <ArgonTypography variant="h6" fontWeight="bold">
                  Guest Reservation:
                </ArgonTypography>
                {bookingPayload?.checkIn ? (
                  <ArgonTypography variant="body1" color="textSecondary">
                    {formatIsoDate(bookingPayload?.checkIn)} -{" "}
                    {formatIsoDate(bookingPayload?.checkOut)}
                  </ArgonTypography>
                ) : (
                  <ArgonTypography variant="body1" color="textSecondary">
                    No Reservation Details
                  </ArgonTypography>
                )}

                <ArgonBox pl={2}>
                  {bookingPayload?.isOwner && (
                    <>
                      <ArgonBox
                        sx={{
                          flexDirection: "column",
                        }}
                      >
                        <ArgonBox>
                          {bookingPayload?.isOwner && !bookingPayload?.checkIn && (
                            <ArgonTypography
                              variant="caption"
                              textTransform="capitalize"
                              sx={{
                                ":hover": {
                                  textDecoration: "underline",
                                  color: "primary.main",
                                },
                                cursor: "pointer",
                                fontStyle: "italic",
                              }}
                              onClick={() =>
                                setShowEditReservationDetails(true)
                              }
                            >
                              {/* {t("serviceAssignments.buttons.editAssignee")} */}
                              Edit Reservation Details
                            </ArgonTypography>
                          )}
                        </ArgonBox>
                        <ArgonBox>
                          {bookingPayload?.isOwner &&
                            bookingPayload?.checkIn &&
                            !bookingPayload?.isIntegration && (
                              <ArgonButton
                                variant="outlined"
                                color="secondary"
                                onClick={clearReservationDetails}
                                sx={{
                                  ml: "auto",
                                  alignSelf: "flex-start",
                                  py: 1.25,
                                  // pr: 1.5,
                                  borderRadius: "8px",
                                  borderWidth: "1px",
                                  transition:
                                    "background-color 0.3s, transform 0.2s",
                                  ":hover": {
                                    backgroundColor: "rgba(0,0,0,0.05)",
                                    transform: "scale(1.02)",
                                  },
                                }}
                              >
                                Clear Reservation
                              </ArgonButton>
                            )}
                        </ArgonBox>
                      </ArgonBox>

                      {/* Button to clear Reservation details */}
                    </>
                  )}
                </ArgonBox>
              </Grid>
            )}

            {/* Recurrence */}
            {bookingPayload?.repeatEvent === true ? (
              <Grid item xs={12}>
                <ArgonTypography variant="h6" fontWeight="bold">
                  Repeats:
                </ArgonTypography>
                <ArgonTypography variant="body1" color="textSecondary">
                  Every {bookingPayload?.recurrenceData?.value || "None"}{" "}
                  {userSelections?.recurrenceIntervalLabel || "None"}
                  {bookingPayload?.recurrenceData?.repeatEndDate
                    ? ` until ${bookingPayload?.recurrenceData?.repeatEndDate} `
                    : null}
                </ArgonTypography>
                <ArgonBox pl={2}>
                  {bookingPayload?.isOwner ? (
                    <ArgonTypography
                      variant="caption"
                      textTransform="capitalize"
                      sx={{
                        ":hover": {
                          textDecoration: "underline",
                          color: "primary.main",
                        },
                        cursor: "pointer",
                        fontStyle: "italic",
                      }}
                      onClick={() => setShowEditRecurrence(true)}
                    >
                      {/* {t("serviceAssignments.buttons.editAssignee")} */}
                      Edit Recurrence
                    </ArgonTypography>
                  ) : null}
                </ArgonBox>
              </Grid>
            ) : null}

            {/* Duration */}
            {bookingPayload?.estimatedDuration ? (
              <Grid item xs={12}>
                <ArgonTypography variant="h6" fontWeight="bold">
                  {t("serviceAssignments.eventDataCard.estimatedDuration")}:
                </ArgonTypography>
                <ArgonTypography variant="body1" color="textSecondary">
                  {bookingPayload?.estimatedDuration?.value || "None"}{" "}
                  {bookingPayload?.estimatedDuration?.interval || "None"}
                </ArgonTypography>
                <ArgonBox pl={2}>
                  <ArgonTypography
                    variant="caption"
                    textTransform="capitalize"
                    sx={{
                      ":hover": {
                        textDecoration: "underline",
                        color: "primary.main",
                      },
                      cursor: "pointer",
                      fontStyle: "italic",
                    }}
                    onClick={() => setShowEditEstimatedDuration(true)}
                  >
                    {/* {t("serviceAssignments.buttons.editAssignee")} */}
                    Edit Estimated Duration
                  </ArgonTypography>
                </ArgonBox>
              </Grid>
            ) : null}

            <Grid item xs={12}>
              <ArgonTypography variant="h6" fontWeight="bold">
                {t("components.serviceName.title")}:
              </ArgonTypography>
              <ArgonTypography variant="body1" color="textSecondary">
                {" "}
                {userSelections[bookingPayload?.serviceNameId] ||
                  "Default Value"}
              </ArgonTypography>
              <ArgonBox pl={2}>
                {bookingPayload?.isOwner ? (
                  <ArgonTypography
                    variant="caption"
                    textTransform="capitalize"
                    sx={{
                      ":hover": {
                        textDecoration: "underline",
                        color: "primary.main",
                      },
                      cursor: "pointer",
                      fontStyle: "italic",
                    }}
                    onClick={() => setShowEditServiceName(true)}
                  >
                    {/* {t("serviceAssignments.buttons.editAssignee")} */}
                    Edit Service Name
                  </ArgonTypography>
                ) : null}
              </ArgonBox>
            </Grid>
            <Grid item xs={12}>
              <ArgonTypography variant="h6" fontWeight="bold">
                {t("components.servicePartner.title")}:
              </ArgonTypography>
              <ArgonTypography variant="body1" color="textSecondary">
                {" "}
                {userSelections[bookingPayload?.assignedCompanyId] ||
                  "Default Value"}
              </ArgonTypography>
              <ArgonBox pl={2}>
                {bookingPayload?.isOwner ? (
                  <ArgonTypography
                    variant="caption"
                    textTransform="capitalize"
                    sx={{
                      ":hover": {
                        textDecoration: "underline",
                        color: "primary.main",
                      },
                      cursor: "pointer",
                      fontStyle: "italic",
                    }}
                    onClick={() => setShowEditServicePartner(true)}
                  >
                    {/* {t("serviceAssignments.buttons.editAssignee")} */}
                    Edit Service Partner
                  </ArgonTypography>
                ) : null}
              </ArgonBox>
            </Grid>
            {bookingPayload?.assignedCompanyId === company?.id ? (
              <Grid item xs={12}>
                <ArgonTypography variant="h6" fontWeight="bold">
                  Assigned Team:
                </ArgonTypography>

                {/* <ArgonTypography variant="body1" color="textSecondary">
                  {userSelections[bookingPayload?.assignedUserSubId] ||
                    "Unassigned"}
                </ArgonTypography> */}
                {bookingAssignees.length > 0 ? (
                  <ArgonBox pl={2}>
                    <Grid container spacing={1} flexDirection="column">
                      {bookingAssignees.map((assignee) => (
                        <ArgonBox alignItems="center" alignContent="center">
                          <Grid>
                            <ArgonTypography
                              variant="caption"
                              textTransform="capitalize"
                            >
                              {assignee?.label}
                            </ArgonTypography>
                            {assignee.value === user?.uid ||
                            role === "admin" ? (
                              <Icon
                                color="error"
                                fontSize="default"
                                sx={{
                                  ":hover": {
                                    textDecoration: "underline",
                                    color: "primary.main",
                                  },
                                  cursor: "pointer",
                                  fontStyle: "italic",
                                }}
                                onClick={() =>
                                  handleRemoveFromBooking(assignee.value)
                                }
                              >
                                <RemoveCircleOutlineIcon />
                              </Icon>
                            ) : null}
                          </Grid>
                        </ArgonBox>
                      ))}
                    </Grid>
                  </ArgonBox>
                ) : null}
                <ArgonBox pl={2}>
                  <ArgonTypography
                    variant="caption"
                    textTransform="capitalize"
                    sx={{
                      ":hover": {
                        textDecoration: "underline",
                        color: "primary.main",
                      },
                      cursor: "pointer",
                      fontStyle: "italic",
                    }}
                    onClick={() => setShowEditAssignee(true)}
                  >
                    Edit Assignment
                  </ArgonTypography>
                </ArgonBox>
              </Grid>
            ) : null}

            {bookingPayload?.checklistId ? (
              <Grid item xs={12}>
                <ArgonTypography variant="h6" fontWeight="bold">
                  {t("components.checklist.title")}:
                </ArgonTypography>
                <ArgonTypography variant="body1" color="textSecondary">
                  {" "}
                  {userSelections[bookingPayload?.checklistId] ||
                    "Default Value"}
                </ArgonTypography>
                <ArgonBox pl={2}>
                  {bookingPayload?.isOwner && isAuthorized ? (
                    <ArgonTypography
                      variant="caption"
                      textTransform="capitalize"
                      sx={{
                        ":hover": {
                          textDecoration: "underline",
                          color: "primary.main",
                        },
                        cursor: "pointer",
                        fontStyle: "italic",
                      }}
                      onClick={() => setShowEditChecklist(true)}
                    >
                      {/* {t("serviceAssignments.buttons.editAssignee")} */}
                      Edit Checklist
                    </ArgonTypography>
                  ) : null}
                </ArgonBox>
              </Grid>
            ) : null}

            {bookingPayload?.isOwner && isAuthorized ? (
              <Grid item xs={12}>
                <ArgonTypography variant="h6" fontWeight="bold">
                  {t("components.category.title")}:
                </ArgonTypography>
                <ArgonTypography variant="body1" color="textSecondary">
                  {" "}
                  {bookingPayload?.categoryId
                    ? userSelections[bookingPayload?.categoryId] || "?"
                    : null}
                </ArgonTypography>
                <ArgonBox pl={2}>
                  <ArgonTypography
                    variant="caption"
                    textTransform="capitalize"
                    sx={{
                      ":hover": {
                        textDecoration: "underline",
                        color: "primary.main",
                      },
                      cursor: "pointer",
                      fontStyle: "italic",
                    }}
                    onClick={() => setShowEditCategory(true)}
                  >
                    {/* {t("serviceAssignments.buttons.editAssignee")} */}
                    Edit Category
                  </ArgonTypography>
                </ArgonBox>
              </Grid>
            ) : null}
            {isInCreationMode ? null : (
              <Grid item xs={12}>
                <ArgonTypography variant="h6" fontWeight="bold">
                  {t("serviceAssignments.eventDataCard.appointmentNotes")}:
                </ArgonTypography>
                <ArgonTypography variant="body1" color="textSecondary">
                  {" "}
                  {bookingPayload?.nextCheckin?.hiddenByOwner ===
                  true ? null : bookingPayload?.nextCheckin?.checkIn ? (
                    <ArgonTypography component="label" variant="caption">
                      ❗️
                      {t(
                        "serviceAssignments.components.viewServiceAppointment.nextCheckInComment.pt1"
                      )}{" "}
                      <strong>{bookingPayload?.nextCheckin?.checkIn} </strong>
                      {t(
                        "serviceAssignments.components.viewServiceAppointment.nextCheckInComment.pt2"
                      )}{" "}
                      <strong>
                        {bookingPayload?.nextCheckin?.stayLengthDays}{" "}
                        {t(
                          "serviceAssignments.components.viewServiceAppointment.nextCheckInComment.nights"
                        )}
                        .
                      </strong>
                    </ArgonTypography>
                  ) : null}
                  <Divider />
                  {bookingPayload?.isOwner &&
                  bookingPayload?.integrationTypeId ? (
                    <ArgonTypography component="label" variant="caption">
                      Scheduled by{" "}
                      <strong>{bookingPayload?.integrationTypeName}.</strong>
                    </ArgonTypography>
                  ) : null}
                  <Divider />
                  {bookingPayload?.bookingNotes ? (
                    <div
                      dangerouslySetInnerHTML={{
                        __html: `${bookingPayload?.bookingNotes}`,
                      }}
                    />
                  ) : null}
                </ArgonTypography>
              </Grid>
            )}
            {isInCreationMode ? null : (
              <Grid item xs={12}>
                <ArgonTypography variant="h6" fontWeight="bold">
                  Service Photos:
                </ArgonTypography>
                <ArgonBox
                  component="input"
                  name="file"
                  type="file"
                  multiple
                  onChange={uploadHandler}
                  mb={2} // Add some margin-bottom for better spacing
                />

                {photoUploadInProgress && (
                  <ArgonProgress value={photoProgress} color="success" />
                )}
                {bookingImages.length > 0 ? (
                  <ArgonBox display="flex" pt={2}>
                    <ArgonBox>
                      <ArgonTypography
                        component="label"
                        variant="caption"
                        fontWeight="bold"
                      >
                        Photo Grid
                      </ArgonTypography>
                    </ArgonBox>
                    <ArgonBox pl={2}>
                      <Switch
                        checked={!imageXL}
                        onChange={() => setImageXL(!imageXL)}
                      />
                    </ArgonBox>
                  </ArgonBox>
                ) : (
                  <ArgonBox pl={1}>
                    <ArgonTypography component="label" variant="caption">
                      No photos uploaded
                    </ArgonTypography>
                  </ArgonBox>
                )}

                <Grid container spacing={3}>
                  <Grid item xs={12}>
                    <HostHiveImages
                      images={bookingImages}
                      handleDeleteImage={handleImageDelete}
                      showNoImagesMessage={true}
                      imageGridSizeXs={imageXL ? 12 : 3}
                      imageGridSizeMd={imageXL ? 12 : 3}
                    />
                  </Grid>
                </Grid>
              </Grid>
            )}

            {isInCreationMode ? null : isAuthorized ? (
              <>
                <Grid item xs={12}>
                  <ArgonBox ml={0.5} lineHeight={0} display="inline-block">
                    <ArgonTypography
                      component="label"
                      variant="caption"
                      fontWeight="bold"
                      textTransform="capitalize"
                    >
                      Comments:
                    </ArgonTypography>
                  </ArgonBox>
                  <HostHiveCommentSection
                    entityType="serviceAppointment"
                    entityId={bookingPayload?.id}
                  />
                </Grid>
              </>
            ) : null}
          </Grid>
        </ArgonBox>
      </Card>
    </>
  );
}

export default ViewEventDataCard;
