import useAxiosPrivate from "hooks/useAxiosPrivate";
import { UserAuth } from "context/AuthContext";
import { isRouteAuthorized } from "services/checkRouteProjection";
function BookingsApi() {
  const axiosPrivate = useAxiosPrivate();
  const { role, hasRole, hasOneOfRoles, subscriptionTier, user, pending } =
    UserAuth();
  const isAuthorized = isRouteAuthorized("calendar", role, subscriptionTier);

  if (!isAuthorized) {
    return null;
  }
  const getBookings = (queryParams) => {
    return axiosPrivate.get(`/bookings`, { params: queryParams });
  };

  const getBookingsVolume = (queryParams) => {
    return axiosPrivate.get(`/bookings/summary/volumeChart`, {
      params: queryParams,
    });
  };

  const getIntegrationBookings = (property_id, integrationTypeId) => {
    return axiosPrivate.get(
      `/bookings/${property_id}/integration/${integrationTypeId}`
    );
  };

  const updateBookingReservationDetails = (bookingId, reservationDetails) => {
    return axiosPrivate.put(
      `/bookings/${bookingId}/reservationDetails`,
      reservationDetails
    );
  };

  const getBookingOwnerInfo = (bookingId) => {
    return axiosPrivate.get(`/bookings/${bookingId}/owner-info`);
  };

  const getServiceGaps = (property_id, queryParams) => {
    return axiosPrivate.get(`/bookings/service-gaps/${property_id}`, {
      params: queryParams,
    });
  };
  const getNextCheckin = (bookingId) => {
    return axiosPrivate.get(`/bookings/next-checkin/${bookingId}`);
  };
  //getNextCheckin
  const createBooking = (bookingData) => {
    return axiosPrivate.post(`/bookings`, bookingData);
  };

  const clearIntegrationBookings = (
    propertyId,
    integrationTypeId,
    bookingData
  ) => {
    return axiosPrivate.post(
      `/bookings/${propertyId}/clearIntegrationBookings/${integrationTypeId}`,
      bookingData
    );
  };

  const getBooking = (bookingId) => {
    return axiosPrivate.get(`/bookings/${bookingId}`);
  };

  const getBookingCalendarUrl = (propertyId) => {
    return axiosPrivate.get(`/bookings/${propertyId}/ics`);
  };

  const getEventsToClear = (queryParams) => {
    return axiosPrivate.get(`/bookings/calendar/clear`, {
      params: queryParams,
    });
  };

  const clearCalendarEvents = (queryParams) => {
    return axiosPrivate.put(`/bookings/calendar/clear`, {
      params: queryParams,
    });
  };

  const updateBooking = (bookingId, bookingData) => {
    return axiosPrivate.put(`/bookings/${bookingId}`, bookingData);
  };

  const removeIntegration = (bookingId) => {
    return axiosPrivate.put(`/bookings/${bookingId}/removeIntegration`);
  };

  const updateBookingStatus = (bookingId, status) => {
    return axiosPrivate.put(`/bookings/${bookingId}/status/${status}`);
  };
  const updateBookingStatusDecline = (bookingId, bookingData) => {
    return axiosPrivate.put(`/bookings/${bookingId}/decline`, bookingData);
  };

  const updateBookingStatuses = (bulkStatusPayload) => {
    return axiosPrivate.put(`/bookings/status/bulk`, bulkStatusPayload);
  };

  const updateBookingSubAssignee = (bookingId, subAssigneeId) => {
    return axiosPrivate.put(
      `/bookings/${bookingId}/subAssigneeId/${subAssigneeId}`
    );
  };

  const updateBookingAssignee = (bookingId, assigneeId) => {
    return axiosPrivate.put(`/bookings/${bookingId}/assigneeId/${assigneeId}`);
  };

  const updateBookingServiceName = (bookingId, serviceNameId) => {
    return axiosPrivate.put(
      `/bookings/${bookingId}/serviceName/${serviceNameId}`
    );
  };

  const updateBookingChecklist = (bookingId, checklistId) => {
    return axiosPrivate.put(`/bookings/${bookingId}/checklist/${checklistId}`);
  };

  const updateBookingCategory = (bookingId, categoryId) => {
    return axiosPrivate.put(`/bookings/${bookingId}/category/${categoryId}`);
  };

  const updateBookingDateTime = (bookingId, dateTimePayload) => {
    return axiosPrivate.put(
      `/bookings/${bookingId}/serviceScheduleDate`,
      dateTimePayload
    );
  };

  //{booking_id}/serviceScheduleDate

  const deleteBooking = (bookingId) => {
    return axiosPrivate.delete(`/bookings/${bookingId}`);
  };

  const dashboardCounts = (queryParams) => {
    return axiosPrivate.get(`/bookings/dashboard/counts/all`, {
      params: queryParams,
    });
  };

  const getBookingAssignees = (bookingId) => {
    return axiosPrivate.get(`/bookings/assigned-users/${bookingId}`);
  };

  const addUserToBooking = (bookingId, userId) => {
    return axiosPrivate.put(`/bookings/assigned-users/${bookingId}/${userId}`);
  };

  const removeUserFromBooking = (bookingId, userId) => {
    return axiosPrivate.delete(
      `/bookings/assigned-users/${bookingId}/${userId}`
    );
  };

  const updateMaxBookingAssignees = (bookingId, maxAssignees) => {
    return axiosPrivate.put(
      `/bookings/max-assignees/${bookingId}/${maxAssignees}`
    );
  };

  return {
    getBookingsVolume,
    updateBookingChecklist,
    updateBookingServiceName,
    updateBookingCategory,
    getBookings,
    createBooking,
    getBooking,
    updateBooking,
    deleteBooking,
    updateBookingStatus,
    updateBookingStatusDecline,
    updateBookingSubAssignee,
    updateBookingStatuses,
    getServiceGaps,
    getBookingCalendarUrl,
    getNextCheckin,
    getIntegrationBookings,
    clearIntegrationBookings,
    removeIntegration,
    getEventsToClear,
    clearCalendarEvents,
    updateBookingAssignee,
    dashboardCounts,
    getBookingOwnerInfo,
    updateBookingDateTime,
    getBookingAssignees,
    addUserToBooking,
    removeUserFromBooking,
    updateMaxBookingAssignees,
    updateBookingReservationDetails,
  };
}

export default BookingsApi;
