/**
=========================================================
* Argon Dashboard 2 PRO MUI - v3.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-pro-mui
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// prop-type is a library for typechecking of props
import PropTypes from "prop-types";

// @mui material components

// Argon Dashboard 2 PRO MUI components

// NewUser page components
import FormField from "layouts/pages/users/new-user/components/FormField";
import Switch from "@mui/material/Switch";

import React, { useEffect, useState, useMemo } from "react";
import FilesApi from "api/filesApi";
import PropertiesApi from "api/propertiesApi";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import ArgonBox from "components/ArgonBox";
import ArgonButton from "components/ArgonButton";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import ArgonInput from "components/ArgonInput";
import ArgonTypography from "components/ArgonTypography";
import ArgonSelect from "components/ArgonSelect";
import ArgonDatePicker from "components/ArgonDatePicker";
import { useTranslation } from "react-i18next";

function ReservationSelection({
  setNextDisabled,
  bookingPayload,
  setBookingPayload,
  setUserSelections,
  userSelections,
  isInCreationMode,
  serviceGapDate = null,
  saveReservationDetailsDisabled,
  setSaveReservationDetailsDisabled,
}) {
  const { t } = useTranslation("common");
  const [checkInDate, setCheckInDate] = useState(
    bookingPayload?.checkIn || null
  );
  const [checkOutDate, setCheckOutDate] = useState(
    bookingPayload?.checkOut || null
  );
  const [serviceScheduleDate, setServiceDate] = useState(
    bookingPayload?.serviceScheduleDate || serviceGapDate || null
  );
  const [serviceScheduleTime, setServiceScheduleTime] = useState({
    value: bookingPayload?.serviceScheduleTime || "10:00",
    label: userSelections?.serviceScheduleTime || "10:00 AM",
  });
  useEffect(() => {
    setBookingPayload({
      ...bookingPayload,
      checkIn: checkInDate,
      checkOut: checkOutDate,
    });

    setUserSelections({
      ...userSelections,
      checkIn: checkInDate,
      checkOut: checkOutDate,
    });

    if (checkInDate != null && checkOutDate != null) {
      if (setNextDisabled) {
        setNextDisabled(false);
      }
    }
  }, [checkInDate, checkOutDate]);
  useEffect(() => {
    console.log("User Selections In date selection", userSelections);
  }, []);

  // useEffect(()=>{

  // })

  const updateBooking = async (key, value) => {
    if (key === "serviceScheduleTime") {
      console.log("Updating serviceScheduleTime to", value);
    } else if (key === "serviceScheduleDate") {
      console.log("Updating serviceScheduleDate to", value);
    } else if (key === "estimatedDuration") {
      console.log("Updating estimatedDuration to", value);
    } else if (key === "recurrenceData") {
      console.log("Updating recurrenceData to", value);
    }
  };

  const handleServiceReservationSelection = (date, dateOption) => {
    let year = date[0].getFullYear();
    let month = date[0].getMonth() + 1;
    let day = date[0].getDate();

    let useDate = `${year}-${month.toString().padStart(2, "0")}-${day
      .toString()
      .padStart(2, "0")}`;
    console.log(useDate);
    // setServiceDate(useDate);
    if (dateOption === "checkIn") {
      setCheckInDate(useDate);
      if (checkOutDate === null) {
        setCheckOutDate(useDate);
      }
    } else {
      setCheckOutDate(useDate);
    }
    setBookingPayload({ ...bookingPayload, dateOption: useDate });
    if (!isInCreationMode) {
      updateBooking(dateOption, useDate);
    }
  };

  return (
    <ArgonBox>
      <ArgonTypography variant="button" fontWeight="regular" color="text">
        Select the guest Check-In and Check-Out dates.
      </ArgonTypography>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <ArgonBox lineHeight={0}>
            <ArgonTypography variant="h5" fontWeight="bold">
              Check-In Date
            </ArgonTypography>
            {/* <ArgonTypography variant="button" fontWeight="regular" color="text">
              Select the date and time of the service
            </ArgonTypography> */}
          </ArgonBox>
          <ArgonDatePicker
            input={{ placeholder: "Select a date" }}
            onChange={(e) => handleServiceReservationSelection(e, "checkIn")}
            enableTime={true}
            value={checkInDate}
          />
        </Grid>

        <Grid item xs={12}>
          <ArgonBox lineHeight={0}>
            <ArgonTypography variant="h5" fontWeight="bold">
              Check-Out Date
            </ArgonTypography>
            {/* <ArgonTypography variant="button" fontWeight="regular" color="text">
              Select the date and time of the service
            </ArgonTypography> */}
          </ArgonBox>
          <ArgonDatePicker
            input={{ placeholder: "Select a date" }}
            onChange={(e) => handleServiceReservationSelection(e, "checkOut")}
            enableTime={true}
            value={checkOutDate}
          />
        </Grid>
      </Grid>
    </ArgonBox>
  );
}

export default ReservationSelection;
