import React, { useState, useEffect } from "react";
import {
  Box,
  Grid,
  Typography,
  Paper,
  Checkbox,
  FormControlLabel,
  Slider,
  TextField,
  Avatar,
  Stack,
  Switch,
  Tabs,
  Tab,
  Divider,
  Icon,
} from "@mui/material";

import HomeIcon from "@mui/icons-material/Home";
import LockIcon from "@mui/icons-material/Lock";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
// import BroomIcon from "@mui/icons-material/Broom";
import TipsAndUpdatesIcon from "@mui/icons-material/TipsAndUpdates";
import CleaningServicesIcon from "@mui/icons-material/CleaningServices";
import Inventory2Icon from "@mui/icons-material/Inventory2";
import HomeRepairServiceIcon from "@mui/icons-material/HomeRepairService";
import BookingReportApi from "api/bookingReportApi";
import HostHiveDialog from "components/HostHiveDialog";
import ArgonButton from "components/ArgonButton";
import ArgonBox from "components/ArgonBox";
import ArgonTypography from "components/ArgonTypography";
import HostHiveCommentSection from "components/HostHiveCommentSection";
import ArgonInput from "components/ArgonInput";
import ArgonProgress from "components/ArgonProgress";

import HostHiveImages from "components/HostHiveImages";
import HostHiveSnackbar from "components/HostHiveSnackbar";

import {
  acceptedImageMimeTypes,
  acceptedImageSizeInMb,
  acceptedVideoMimeTypes,
  acceptedVideoSizeInMb,
  acceptedDocumentMimeTypes,
  acceptedPropertyImageSizeInMb,
} from "services/acceptedMimeTypes";
import { set } from "lodash";
// import { format } from "date-fns";

export default function ServiceReport({
  booking,
  reportModalVisible,
  setReportModalVisible,
  bookingImages,
  setBookingImages,
}) {
  console.log("Booking in ServiceReport", booking);
  const [modalVisible, setModalVisible] = useState(false);
  const { renderSuccessSB, openSuccessSB } = HostHiveSnackbar();
  const bookingReportApi = BookingReportApi();
  const currentDate = booking?.serviceScheduleDate || "December 9, 2024";

  const [recommendations, setRecommendations] = useState("");
  const [summary, setSummary] = useState(
    `${booking.serviceName} - ${booking.serviceScheduleDate}`
  );

  const [imageXL, setImageXL] = useState(false);
  const [tabsOrientation, setTabsOrientation] = useState("horizontal");
  const [tabValue, setTabValue] = useState(0);
  //   const [bookingImages, setBookingImages] = useState([]);

  const [photoUploadInProgress, setPhotoUploadInProgress] = useState(false);
  const [photoProgress, setPhotoProgress] = useState(0);
  const [erroredPhotos, setErroredPhotos] = useState([]);
  const [sendEmailUpdate, setSendEmailUpdate] = useState(true);
  const [existingBookingReport, setExistingBookingReport] = useState(null);

  const [suppliesTabValue, setSuppliesTabValue] = useState(0);
  const [cleanlinessTabValue, setCleanlinessTabValue] = useState(0);
  const [propertyConditionTabValue, setPropertyConditionTabValue] = useState(0);
  const [homeLocked, setHomeLocked] = useState(0);
  const [readyForGuests, setreadyForGuests] = useState(0);
  const [amenitiesReady, setAmenitiesReady] = useState(0);
  const [defaultReport, setDefaultReport] = useState(false);
  useEffect(() => {
    async function fetchBookingReport() {
      try {
        const response = await bookingReportApi.getBookingReport(booking?.id, {
          newReport: true,
        });
        console.log("Booking report response", response);
        let ownerSubscriptionTier = response.data.ownerSubscriptionTier;

        // check if ownerSubscriptionTier is null, free, or undefined, if so, navigate to /
        if (
          ownerSubscriptionTier === null ||
          ownerSubscriptionTier === "free" ||
          ownerSubscriptionTier === undefined
        ) {
          console.log("Owner subscription tier is null, free, or undefined");
        }
        if (response?.data) {
          setExistingBookingReport(response.data);

          setAmenitiesReady(response.data.amenitiesReady === false ? 0 : 1);
          setHomeLocked(response.data.homeLocked === false ? 0 : 1);
          setreadyForGuests(response.data.readyForGuests === false ? 0 : 1);
          setCleanlinessTabValue(response.data.ratingCleanliness - 1);
          setSuppliesTabValue(response.data.ratingSuppliesStocked - 1);
          setPropertyConditionTabValue(
            response.data.ratingPropertyCondition - 1
          );
          setRecommendations(response.data.recommendations);
          setDefaultReport(response.data.defaultReport || false);
        } else {
          console.log("No existing booking report found");
        }
        console.log("response from fetching booking report", response.data);
      } catch (error) {
        // navigate("/authentication/error/404");
        console.log("Error getting booking report", error);
      }
    }
    fetchBookingReport();
  }, []);

  const handleSetTabValue = (value, newValue, type) => {
    if (type === "supplies") {
      setSuppliesTabValue(newValue);
    } else if (type === "cleanliness") {
      setCleanlinessTabValue(newValue);
    } else if (type === "propertyCondition") {
      setPropertyConditionTabValue(newValue);
    }
  };

  const handleQuestionAnswered = (event, newValue, type) => {
    if (type === "homeLocked") {
      setHomeLocked(newValue);
    } else if (type === "readyForGuests") {
      setreadyForGuests(newValue);
    } else if (type === "amenitiesReady") {
      setAmenitiesReady(newValue);
    }
  };

  const handleSubmit = async () => {
    console.log("Booking payload", booking);
    let payload = {
      bookingId: booking?.id,
      companyId: booking?.companyId,
      propertyId: booking?.propertyId,
      homeLocked: homeLocked === 1,
      readyForGuests: readyForGuests === 1,
      amenitiesReady: amenitiesReady === 1,
      ratingCleanliness: cleanlinessTabValue + 1,
      ratingSuppliesStocked: suppliesTabValue + 1,
      ratingPropertyCondition: propertyConditionTabValue + 1,
      recommendations,
      summary,
      assigneeId: booking?.assignedCompanyId,
      serviceScheduleDate: booking?.serviceScheduleDate,
      sendEmailUpdate,
    };

    console.log("payload", payload);

    try {
      const response = await bookingReportApi.upsertBookingReportNew(
        booking?.id,
        payload
      );
      console.log("response", response.data);
      if (defaultReport) {
        setDefaultReport(false);
      }
    } catch (error) {
      alert("Error updating booking report");
      console.error("Error updating booking report", error);
    }
  };

  const uploadHandler = async (event) => {
    setPhotoUploadInProgress(true);

    const uploadedImages = event.target.files;
    const uploadedImagesArray = Array.from(uploadedImages);
    const imagesArray = [];
    const photoLength = uploadedImagesArray.length;
    const maxSizeInMB = acceptedImageSizeInMb; // Replace x with the maximum file size in MB
    const allowedMimeTypes = acceptedImageMimeTypes; // Add or remove MIME types as needed

    for (let i = 0; i < uploadedImagesArray.length; i++) {
      setPhotoProgress((i / photoLength) * 100);
      const image = uploadedImagesArray[i];

      if (image.size > maxSizeInMB * 1024 * 1024) {
        console.log(`Error: File size exceeds ${maxSizeInMB}MB`);
        let imageSize = image.size / (1024 * 1024);
        imageSize = imageSize.toFixed(2);
        setErroredPhotos((prevErroredPhotos) => [
          ...prevErroredPhotos,
          `${image.name} - File size exceeds ${maxSizeInMB}MB - ${imageSize}MB`,
        ]);
        continue; // Skip this file
      }

      // Check MIME type
      if (!allowedMimeTypes.includes(image.type)) {
        console.log(
          `Error: Invalid file type. Allowed types are: ${allowedMimeTypes.join(
            ", "
          )}`
        );
        setErroredPhotos((prevErroredPhotos) => [
          ...prevErroredPhotos,
          `${image.name} - Invalid file type - ${image.type}`,
        ]);
        continue; // Skip this file
      }

      const fileType = image.type;
      const fileName = image.name;

      const formData = new FormData();
      formData.append("file", image);
      try {
        const response = await bookingReportApi.updateBookingReportImages(
          booking?.id,
          booking?.companyId,
          booking?.propertyId,
          fileName,
          fileType,
          booking?.summary,
          formData
        );

        imagesArray.push(response.data.images); // Add the uploaded image to the imagesArray
        setBookingImages(response.data.images);
        openSuccessSB(
          `Image uploaded successfully`,
          `Successfully uploaded image`
        );
      } catch (error) {
        console.error(error);
      }
    }

    setPhotoUploadInProgress(false);
    setPhotoProgress(0);

    // Update the bookingImages state with the uploaded images
  };

  const handleImageDelete = async (index, fileName) => {
    console.log("handleImageDelete", fileName);
    try {
      const response = await bookingReportApi.deleteBookingReportImage(
        booking?.id,
        booking?.companyId,
        booking?.propertyId,
        fileName
      );

      // Update the bookingImages state by removing the image at the specified index
      setBookingImages((prevImages) => {
        const newImages = [...prevImages];
        newImages.splice(index, 1);
        return newImages;
      });
      openSuccessSB(`Image deleted successfully`, `Successfully deleted image`);
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <>
      {renderSuccessSB}
      <HostHiveDialog
        open={reportModalVisible}
        onClose={() => setReportModalVisible(false)}
        onSave={() => handleSubmit()}
        includeSave={true}
        includeClose={true}
        title="Complete Appointment"
        fullScreen={false}
        maxWidth="lg"
        cancelButtonText="Close"
        submitButtonText={defaultReport ? "Complete Appointment" : "Save"}
        submitButtonColor="success"
        activateSwitch={defaultReport ? false : true}
        activateSwitchValue={sendEmailUpdate}
        setActivateSwitchValue={setSendEmailUpdate}
        activateSwitchLabel="Send Email Update"
        dialogObjects={
          <>
            <ArgonBox sx={{ p: 3, width: "100%", maxWidth: 700, mx: "auto" }}>
              <ArgonBox sx={{ mb: 3 }}>
                <ArgonTypography variant="h5" fontWeight="bold" gutterBottom>
                  Airbnb Turnover - {currentDate}
                </ArgonTypography>
                <ArgonBox>
                  <ArgonTypography variant="caption" color="text.secondary">
                    {booking?.propertyName}
                  </ArgonTypography>
                </ArgonBox>
                <ArgonBox>
                  <ArgonTypography variant="caption" color="text.secondary">
                    {booking?.propertyAddress?.address1}
                  </ArgonTypography>
                </ArgonBox>
                <Divider sx={{ mt: 2 }} />
              </ArgonBox>

              {/* House Ready for Guests */}
              <ArgonBox sx={{ mb: 4 }}>
                <Grid
                  container
                  spacing={2}
                  sx={{
                    background: "background.default",
                    p: 2,
                    borderRadius: 2,
                    boxShadow: 1,
                  }}
                  alignItems="center"
                >
                  <Grid item xs={12} md={6}>
                    <Grid container alignItems="center" spacing={1}>
                      <Grid item>
                        <HomeIcon color="info" />
                      </Grid>
                      <Grid item>
                        <ArgonTypography variant="h6" fontWeight="medium">
                          House Ready for Guests
                        </ArgonTypography>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <Tabs
                      orientation={tabsOrientation}
                      value={readyForGuests}
                      onChange={(e, newValue) =>
                        handleQuestionAnswered(e, newValue, "readyForGuests")
                      }
                      indicatorColor="primary"
                      textColor="primary"
                    >
                      <Tab label="No" />
                      <Tab label="Yes" />
                    </Tabs>
                  </Grid>
                </Grid>
              </ArgonBox>

              {/* Home is Locked & Secure */}
              <ArgonBox sx={{ mb: 4 }}>
                <Grid
                  container
                  spacing={2}
                  sx={{
                    background: "background.default",
                    p: 2,
                    borderRadius: 2,
                    boxShadow: 1,
                  }}
                  alignItems="center"
                >
                  <Grid item xs={12} md={6}>
                    <Grid container alignItems="center" spacing={1}>
                      <Grid item>
                        <LockIcon color="info" />
                      </Grid>
                      <Grid item>
                        <ArgonTypography variant="h6" fontWeight="medium">
                          Home is Locked & Secure
                        </ArgonTypography>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <Tabs
                      orientation={tabsOrientation}
                      value={homeLocked}
                      onChange={(e, newValue) =>
                        handleQuestionAnswered(e, newValue, "homeLocked")
                      }
                      indicatorColor="primary"
                      textColor="primary"
                    >
                      <Tab label="No" />
                      <Tab label="Yes" />
                    </Tabs>
                  </Grid>
                </Grid>
              </ArgonBox>

              {/* Amenities Ready */}
              <ArgonBox sx={{ mb: 4 }}>
                <Grid
                  container
                  spacing={2}
                  sx={{
                    background: "background.default",
                    p: 2,
                    borderRadius: 2,
                    boxShadow: 1,
                  }}
                  alignItems="center"
                >
                  <Grid item xs={12} md={6}>
                    <Grid container alignItems="center" spacing={1}>
                      <Grid item>
                        <CheckCircleIcon color="info" />
                      </Grid>
                      <Grid item>
                        <ArgonTypography variant="h6" fontWeight="medium">
                          Amenities Ready
                        </ArgonTypography>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <Tabs
                      orientation={tabsOrientation}
                      value={amenitiesReady}
                      onChange={(e, newValue) =>
                        handleQuestionAnswered(e, newValue, "amenitiesReady")
                      }
                      indicatorColor="primary"
                      textColor="primary"
                    >
                      <Tab label="No" />
                      <Tab label="Yes" />
                    </Tabs>
                  </Grid>
                </Grid>
              </ArgonBox>

              <Divider sx={{ mb: 4 }} />

              {/* Cleanliness */}
              <ArgonBox sx={{ mb: 4 }}>
                <Grid
                  container
                  spacing={2}
                  sx={{
                    background: "background.default",
                    p: 2,
                    borderRadius: 2,
                    boxShadow: 1,
                  }}
                  alignItems="center"
                >
                  <Grid item xs={12} md={6}>
                    <Grid container alignItems="center" spacing={1}>
                      <Grid item>
                        <CleaningServicesIcon color="info" />
                      </Grid>
                      <Grid item>
                        <ArgonTypography variant="h6" fontWeight="medium">
                          Cleanliness
                        </ArgonTypography>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <Tabs
                      orientation={tabsOrientation}
                      value={cleanlinessTabValue}
                      onChange={(e, newValue) =>
                        handleSetTabValue(e, newValue, "cleanliness")
                      }
                      indicatorColor="primary"
                      textColor="primary"
                    >
                      <Tab label="1" />
                      <Tab label="2" />
                      <Tab label="3" />
                      <Tab label="4" />
                      <Tab label="5" />
                    </Tabs>
                  </Grid>
                </Grid>
              </ArgonBox>

              {/* Supplies Stocked */}
              <ArgonBox sx={{ mb: 4 }}>
                <Grid
                  container
                  spacing={2}
                  sx={{
                    background: "background.default",
                    p: 2,
                    borderRadius: 2,
                    boxShadow: 1,
                  }}
                  alignItems="center"
                >
                  <Grid item xs={12} md={6}>
                    <Grid container alignItems="center" spacing={1}>
                      <Grid item>
                        <Inventory2Icon color="info" />
                      </Grid>
                      <Grid item>
                        <ArgonTypography variant="h6" fontWeight="medium">
                          Supplies Stocked
                        </ArgonTypography>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <Tabs
                      orientation={tabsOrientation}
                      value={suppliesTabValue}
                      onChange={(e, newValue) =>
                        handleSetTabValue(e, newValue, "supplies")
                      }
                      indicatorColor="primary"
                      textColor="primary"
                    >
                      <Tab label="1" />
                      <Tab label="2" />
                      <Tab label="3" />
                      <Tab label="4" />
                      <Tab label="5" />
                    </Tabs>
                  </Grid>
                </Grid>
              </ArgonBox>

              {/* Property Condition */}
              <ArgonBox sx={{ mb: 4 }}>
                <Grid
                  container
                  spacing={2}
                  sx={{
                    background: "background.default",
                    p: 2,
                    borderRadius: 2,
                    boxShadow: 1,
                  }}
                  alignItems="center"
                >
                  <Grid item xs={12} md={6}>
                    <Grid container alignItems="center" spacing={1}>
                      <Grid item>
                        <HomeRepairServiceIcon color="info" />
                      </Grid>
                      <Grid item>
                        <ArgonTypography variant="h6" fontWeight="medium">
                          Property Condition
                        </ArgonTypography>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <Tabs
                      orientation={tabsOrientation}
                      value={propertyConditionTabValue}
                      onChange={(e, newValue) =>
                        handleSetTabValue(e, newValue, "propertyCondition")
                      }
                      indicatorColor="primary"
                      textColor="primary"
                    >
                      <Tab label="1" />
                      <Tab label="2" />
                      <Tab label="3" />
                      <Tab label="4" />
                      <Tab label="5" />
                    </Tabs>
                  </Grid>
                </Grid>
              </ArgonBox>

              <Divider sx={{ mb: 4 }} />

              {/* Recommendations */}
              <ArgonBox sx={{ mb: 4 }}>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <Grid container alignItems="center" spacing={1}>
                      <Grid item>
                        <TipsAndUpdatesIcon color="info" />
                      </Grid>
                      <Grid item>
                        <ArgonTypography variant="h6" gutterBottom>
                          Recommendations
                        </ArgonTypography>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item xs={12}>
                    <ArgonInput
                      fullWidth
                      value={recommendations}
                      minRows={4}
                      multiline
                      onChange={(e) => setRecommendations(e.target.value)}
                      placeholder="Enter any additional recommendations..."
                      sx={{
                        backgroundColor: "background.paper",
                        border: "1px solid",
                        borderColor: "divider",
                        borderRadius: 1,
                        p: 1,
                      }}
                    />
                  </Grid>
                </Grid>
              </ArgonBox>

              {/* Service Photos */}
              <ArgonBox
                display="flex"
                flexDirection="column"
                alignItems="stretch"
                height="100%"
                p={2}
              >
                <ArgonTypography
                  component="label"
                  variant="caption"
                  fontWeight="bold"
                  mb={2}
                >
                  Service Photos
                </ArgonTypography>
                <ArgonBox
                  component="input"
                  name="file"
                  type="file"
                  multiple
                  onChange={uploadHandler}
                  mb={2}
                />
                {photoUploadInProgress && (
                  <ArgonProgress value={photoProgress} color="success" />
                )}
              </ArgonBox>

              <ArgonBox mt={3}>
                <Grid container spacing={3}>
                  <Grid item xs={12} lg={6} xl={5}>
                    <HostHiveImages
                      images={bookingImages}
                      handleDeleteImage={handleImageDelete}
                      showNoImagesMessage={true}
                      imageGridSizeXs={imageXL ? 12 : 3}
                      imageGridSizeMd={imageXL ? 12 : 3}
                    />
                  </Grid>
                </Grid>
              </ArgonBox>

              {/* Comments Section */}
              <HostHiveCommentSection
                title="Service Comments"
                entityType="serviceAppointment"
                entityId={booking?.id}
              />
            </ArgonBox>
          </>
        }
      />
    </>
  );
}
