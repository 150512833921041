import Slider from "@mui/material/Slider";
import { useState, useEffect, useContext } from "react";
import ArgonBox from "components/ArgonBox";
import ArgonTypography from "components/ArgonTypography";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import PropTypes from "prop-types";
import { Link, useNavigate, useParams } from "react-router-dom";
import Divider from "@mui/material/Divider";

import {
  Card,
  Grid,
  Paper,
  Tooltip,
  Icon,
  FormControl,
  Select,
  MenuItem,
} from "@mui/material";
import Menu from "@mui/material/Menu";

import BookingsApi from "api/bookingsApi";
import ChecklistsApi from "api/checkListsApi";
import CompaniesApi from "api/companyApi";
import ServiceNameApi from "api/service-nameApi";
import UsersApi from "api/usersApi";

import ArgonButton from "components/ArgonButton";
import ServiceReport from "layouts/management/components/ServiceReport";
import { showDeleteConfirmation } from "services/confirmDelete";

import ReadOnlyPropertyDetails from "layouts/management/properties/properties-list/components/read-only-property-details";

import HostHiveDialog from "components/HostHiveDialog";

import ServiceAppointmentChangeRequest from "layouts/management/components/ServiceAppointmentChangeRequest";
import DeclineMessageDataCard from "layouts/management/components/DeclineMessageDataCard";
import RecurrenceDialog from "layouts/management/components/RecurrenceDialog";
import RecurrenceSeriesDialog from "layouts/management/components/RecurrenceSeriesDialog";
import BookingReportDataCard from "layouts/management/components/BookingReportDataCard";
import { UserAuth } from "context/AuthContext";
import { isRouteAuthorized } from "services/checkRouteProjection";
import { set } from "lodash";
function EventMenu({
  // openMenu,
  // setOpenMenu,
  // handleCloseMenu,
  closeEventDataCard,
  bookingPayload,
  setBookingPayload,
  disableViewPropertyDetails,
  changeRequestPending,
  isAppointmentOwner,
  changeRequestData = null,
  handleRefetchBookings = null,
  setFetchChangeRequestDetails = null,
  bookingImages,
  setBookingImages,
}) {
  const { role, hasRole, user, hasOneOfRoles, subscriptionTier } = UserAuth();
  const isAuthorized = isRouteAuthorized(
    "calendarPlus",
    role,
    subscriptionTier
  );
  const [openMenu, setOpenMenu] = useState(null);
  // console.log("Event Menu", bookingPayload, isAppointmentOwner);
  const company = JSON.parse(localStorage.getItem("companyDetails"));
  // const isAppointmentOwner = bookingPayload?.isOwner;
  const handleOpenMenu = (event) => setOpenMenu(event.currentTarget);
  const handleCloseMenu = () => setOpenMenu(null);
  const [requestChangesDialogOpen, setRequestChangesDialogOpen] =
    useState(false);
  const [showPropertyDataCard, setShowPropertyDataCard] = useState(false);
  const [declineAppointmentOpen, setDeclineAppointmentOpen] = useState(false);
  const [serviceReportOpen, setServiceReportOpen] = useState(false);
  const [showReviewChangeRequest, setShowReviewChangeRequest] = useState(false);

  const [recurrenceSeriesDialogOpen, setRecurrenceSeriesDialogOpen] =
    useState(false);
  const [recurrenceConfigurationOpen, setRecurrenceConfigurationOpen] =
    useState(false);
  const [recurrenceData, setRecurrenceData] = useState(null); // [startDate, endDate, interval, intervalType, recurrenceType
  const [repeatEvent, setRepeatEvent] = useState(false);
  const [recurrenceAllowed, setRecurrenceAllowed] = useState(true);
  const [updateSeries, setUpdateSeries] = useState(null);
  const [reportModalVisible, setReportModalVisible] = useState(false);
  const bookingsApi = new BookingsApi();
  const checklistsApi = new ChecklistsApi();
  const navigate = useNavigate();
  const [max, setMax] = useState(bookingPayload?.maxSubAssignees || 12);
  const confirmUserAction = async (
    message,
    messageSubText,
    buttonConfirmationText
  ) => {
    try {
      await showDeleteConfirmation(
        message,
        messageSubText,
        buttonConfirmationText
      );
      console.log(`User confirmed deletion!`);

      return true;
    } catch (response) {
      console.log("User cancelled deletion!");
      return false;
    }
  };

  const handleStartAppointment = async () => {
    console.log("Appointment in progress", bookingPayload?.id);
    const status = "inProgress";
    try {
      const updateBookingStatusResponse = await bookingsApi.updateBookingStatus(
        bookingPayload?.id,
        status
      );
      console.log("Booking status updated", updateBookingStatusResponse);
      setBookingPayload({
        ...bookingPayload,
        status: status,
      });
    } catch (error) {
      console.error("Error updating booking status", error);
    }
    //   onClose();
  };

  const handleConfirmBooking = async () => {
    console.log("Booking confirmed", bookingPayload?.id);
    const status = "confirmed";
    try {
      const updateBookingStatusResponse = await bookingsApi.updateBookingStatus(
        bookingPayload?.id,
        status
      );
      console.log("Booking status updated", updateBookingStatusResponse);
      setBookingPayload({
        ...bookingPayload,
        status: status,
      });
    } catch (error) {
      console.error("Error updating booking status", error);
    }
    //   onClose();
  };

  const handleCancelClicked = async () => {
    // setLoadingText("Cancelling service assignment...");
    // setSavingAssignment(true);
    let message = `Are you sure you want to cancel this appointment?`;
    let messageSubText = "This action cannot be undone.";
    let buttonConfirmationText = "Yes";
    const deleteConfirmation = await confirmUserAction(
      message,
      messageSubText,
      buttonConfirmationText
    );
    if (deleteConfirmation) {
      try {
        await bookingsApi.deleteBooking(bookingPayload?.id);
        console.log("Booking cancelled");
        if (handleRefetchBookings) {
          closeEventDataCard();
          handleRefetchBookings();
        }
      } catch (error) {
        console.error(error);
      }
    } else {
      console.log("User cancelled deletion!");
    }
  };

  const removeIntegrationLink = async () => {
    let message = `Are you sure you want remove this appointment from the integration series?`;
    let messageSubText = "Remove Appointment from Integration Series";
    let buttonConfirmationText = "Yes";
    const deleteConfirmation = await confirmUserAction(
      message,
      messageSubText,
      buttonConfirmationText
    );
    if (deleteConfirmation) {
      try {
        const response = await bookingsApi.removeIntegration(
          bookingPayload?.id
        );
        if (handleRefetchBookings) {
          handleRefetchBookings();
        }
      } catch (error) {
        console.error(error);
      }
    }
  };

  const handleNavigateToReport = () => {
    ///properties/:propertyId/bookingReport/:bookingReportId
    navigate(
      `/service-reports/${bookingPayload?.propertyId}/${bookingPayload?.id}`
    );
  };

  const handleChangeRequestOpen = () => {
    if (!changeRequestPending) {
      setRequestChangesDialogOpen(true);
    } else {
      setRequestChangesDialogOpen(true);
    }
  };

  const handleCommentSubscription = () => {
    console.log("Unsubscribe from comments");
  };

  const [updateMaxTeamMembers, setUpdateMaxTeamMembers] = useState(false);

  const renderMenu = () => (
    <Menu
      anchorEl={openMenu}
      anchorOrigin={{ vertical: "top", horizontal: "left" }}
      transformOrigin={{ vertical: "top", horizontal: "right" }}
      open={Boolean(openMenu)}
      onClose={handleCloseMenu}
      keepMounted
    >
      {bookingPayload?.assignedCompanyId === company?.id && role === "admin" ? (
        <MenuItem
          onClick={() => {
            setUpdateMaxTeamMembers(true) &&
              handleCloseMenu() &&
              closeEventDataCard();
          }}
        >
          Update Max Team Members
        </MenuItem>
      ) : null}
      {!isAppointmentOwner ? (
        <>
          {bookingPayload?.status === "scheduled" ? (
            <MenuItem
              onClick={() => {
                handleConfirmBooking() && handleCloseMenu();
              }}
            >
              Accept Appointment
            </MenuItem>
          ) : null}
          <MenuItem
            onClick={() => {
              setDeclineAppointmentOpen(true) &&
                handleCloseMenu() &&
                closeEventDataCard();
            }}
          >
            Decline Appointment
          </MenuItem>
          {changeRequestPending === null ? (
            <MenuItem disabled={true}>Loading change request info</MenuItem>
          ) : (
            <MenuItem
              disabled={!isAuthorized}
              onClick={() => {
                handleChangeRequestOpen();
                handleCloseMenu();
              }}
            >
              {changeRequestPending ? "View Change Request" : "Request Changes"}
            </MenuItem>
          )}
        </>
      ) : null}

      {bookingPayload?.status === "confirmed" ? (
        <MenuItem
          onClick={() => {
            handleStartAppointment() && handleCloseMenu();
          }}
        >
          Set Status to In Progress
        </MenuItem>
      ) : null}

      {isAppointmentOwner ? (
        <>
          <MenuItem
            onClick={() => {
              handleCancelClicked() && handleCloseMenu();
            }}
          >
            Cancel Appointment
          </MenuItem>
        </>
      ) : null}
      {isAppointmentOwner && bookingPayload?.isIntegration ? (
        <MenuItem
          onClick={() => {
            removeIntegrationLink() && handleCloseMenu();
          }}
        >
          Remove Integration Link
        </MenuItem>
      ) : null}

      <Divider />

      {/* {["confirmed", "inProgress"].includes(bookingPayload?.status) ? (
        <MenuItem
        // onClick={() => {
        //   navigate(`/service-reports/${property?.id}`) &&
        //     handleCloseMenu();
        // }}
        >
          View Access Details
        </MenuItem>
      ) : null} */}
      {disableViewPropertyDetails ? null : (
        <MenuItem
          onClick={() => {
            setShowPropertyDataCard(true) && handleCloseMenu();
          }}
        >
          View Property Details
        </MenuItem>
      )}

      <MenuItem
        disabled={
          bookingPayload?.ownerSubscriptionTier === "free" ||
          !bookingPayload?.ownerSubscriptionTier
        }
        onClick={() => {
          setReportModalVisible(true) && handleCloseMenu();
        }}
      >
        Service Report
      </MenuItem>
    </Menu>
  );
  const handleSubmitClicked = async () => {
    console.log("Submitting");
    console.log("Recurrence Data", recurrenceData);
    console.log("Update Series", updateSeries);
  };

  const handleSettingChange = (newValue) => {
    // const newSettings = { ...settings };
    // newSettings[section].settings[index].value = newValue;
    // setSettings(newSettings);
    console.log(newValue);
    setMax(newValue);
  };

  const handleSaveMaxTeamMembers = async () => {
    try {
      await bookingsApi.updateMaxBookingAssignees(bookingPayload?.id, max);
      setUpdateMaxTeamMembers(false);
      let updatedBookingPayload = { ...bookingPayload };
      updatedBookingPayload.maxSubAssignees = max;
      setBookingPayload(updatedBookingPayload);
    } catch (error) {
      console.error("Error updating max team members", error);
    }
  };

  return (
    <>
      {renderMenu()}
      <ServiceReport
        booking={bookingPayload}
        bookingImages={bookingImages}
        setBookingImages={setBookingImages}
        reportModalVisible={reportModalVisible}
        setReportModalVisible={setReportModalVisible}
      />
      <HostHiveDialog
        includeDelete={false}
        includeSave={true}
        onSave={handleSaveMaxTeamMembers}
        open={updateMaxTeamMembers}
        onClose={() => {
          setUpdateMaxTeamMembers(false);
        }}
        title="Property Details"
        fullScreen={false}
        maxWidth="xs"
        cancelButtonText="Close"
        submitButtonText="Save"
        dialogObjects={
          <>
            {/* <Card>
                <ArgonBox p={2}>
                  <ArgonBox>Test</ArgonBox>
                </ArgonBox>
              </Card> */}
            <>
              <ArgonBox>
                <ArgonBox sx={{ width: 300 }}>
                  <Slider
                    value={max}
                    onChange={(newValue) =>
                      handleSettingChange(newValue.target.value)
                    }
                  />
                </ArgonBox>
                <ArgonTypography variant="caption">
                  Max Team Members: {max}
                </ArgonTypography>
              </ArgonBox>
            </>
          </>
        }
      />

      <HostHiveDialog
        includeDelete={false}
        includeSave={false}
        open={showPropertyDataCard}
        onClose={() => {
          setShowPropertyDataCard(false);
        }}
        title="Property Details"
        fullScreen={false}
        maxWidth="xl"
        cancelButtonText="Close"
        submitButtonText="Save"
        dialogObjects={
          <>
            {/* <Card>
                <ArgonBox p={2}>
                  <ArgonBox>Test</ArgonBox>
                </ArgonBox>
              </Card> */}
            <ReadOnlyPropertyDetails
              property={bookingPayload?.propertyAddress}
              propertyId={bookingPayload?.propertyId}
              propertyName={bookingPayload?.propertyName}
              isOwner={isAppointmentOwner}
            />
          </>
        }
      />
      {/* <HostHiveDialog
        open={serviceReportOpen}
        fullWidth={true}
        includeSave={false}
        includeClose={false}
        maxWidth="md"
        // onClose={handleCancelClicked}
        // onSave={handleSubmitClicked}
        title="Booking Report"
        fullScreen={false}
        // cancelButtonText="Cancel"
        // submitButtonText="Save"
        showStatusBadge={true}
        statusColor="success"
        statusContent="Confirmed"
        dialogObjects={
          <BookingReportDataCard
            propertyId={property?.id}
            companyId={company?.id}
            bookingReportId={bookingPayload?.id}
            onClose={() => setServiceReportOpen(false)}
          />
        }
      /> */}
      {/* <HostHiveDialog
        open={recurrenceConfigurationOpen}
        fullWidth={true}
        includeSave={false}
        includeClose={false}
        maxWidth="sm"
        // onClose={handleCancelClicked}
        // onSave={handleSubmitClicked}
        title="Recurrence Configuration"
        fullScreen={false}
        // cancelButtonText="Cancel"
        // submitButtonText="Save"
        showStatusBadge={true}
        statusColor="success"
        statusContent="Confirmed"
        dialogObjects={
          <RecurrenceDialog
            onClose={() => setRecurrenceConfigurationOpen(false)}
            setRecurrenceData={setRecurrenceData}
            recurrenceData={recurrenceData}
          />
        }
      /> */}
      <HostHiveDialog
        open={recurrenceSeriesDialogOpen}
        fullWidth={true}
        includeSave={false}
        includeClose={false}
        maxWidth="sm"
        title="Recurrence Series Configuration"
        fullScreen={false}
        showStatusBadge={false}
        statusColor="success"
        statusContent="Confirmed"
        dialogObjects={
          <RecurrenceSeriesDialog
            onClose={() => setRecurrenceSeriesDialogOpen(false)}
            setUpdateSeries={setUpdateSeries}
            handleSubmitClicked={handleSubmitClicked}
            allAppointmentsText="All New & Existing Appointments"
            onlyNewAppointmentsText="Only New Appointments"
            submiteButtonText="Apply"
            allAppointmentsValue="existingAndNew"
            onlyNewValue="onlyNew"
          />
        }
      />
      <HostHiveDialog
        open={declineAppointmentOpen}
        fullWidth={true}
        includeSave={false}
        includeClose={false}
        maxWidth="md"
        title="Decline Service Appointment"
        fullScreen={false}
        cancelButtonText="Cancel"
        submitButtonText="Save"
        showStatusBadge={false}
        statusColor="success"
        statusContent={bookingPayload?.status}
        dialogObjects={
          <DeclineMessageDataCard
            // confirmBookingsFetch={confirmBookingsFetch}
            // setConfirmBookingsFetch={setConfirmBookingsFetch}
            eventDataCardClose={closeEventDataCard}
            bookingPayload={bookingPayload}
            onClose={() => {
              setDeclineAppointmentOpen(false);
            }}
            handleRefetchBookings={handleRefetchBookings}

            // setExternalFetch={
            //   setExternalFetch
            //     ? setExternalFetch
            //     : setRefetchBookings
            //     ? setRefetchBookings
            //     : null
            // }
          />
        }
      />
      <HostHiveDialog
        open={requestChangesDialogOpen}
        fullWidth={true}
        includeSave={false}
        includeClose={false}
        maxWidth="sm"
        // onClose={handleCancelClicked}
        // onSave={handleSubmitClicked}
        title="Change Request"
        fullScreen={false}
        // cancelButtonText="Cancel"
        // submitButtonText="Save"
        showStatusBadge={false}
        // statusColor="success"
        // statusContent="Confirmed"
        dialogObjects={
          <ServiceAppointmentChangeRequest
            bookingInfo={bookingPayload}
            onClose={() => setRequestChangesDialogOpen(false)}
            changeRequestData={changeRequestData}
            setFetchChangeRequestDetails={setFetchChangeRequestDetails}
          />
        }
      />
      <ArgonButton
        variant="outlined"
        color="secondary"
        onClick={handleOpenMenu}
        sx={{
          ml: "auto",
          alignSelf: "flex-start",
          py: 1.25,
          pr: 1.5,
          borderRadius: "8px",
          borderWidth: "1px",
          transition: "background-color 0.3s, transform 0.2s",
          ":hover": {
            backgroundColor: "rgba(0,0,0,0.05)",
            transform: "scale(1.02)",
          },
        }}
        startIcon={
          <Icon fontSize="default" sx={{ cursor: "pointer" }}>
            settings
          </Icon>
        }
      >
        Appointment Options
      </ArgonButton>
    </>
  );
}

export default EventMenu;
